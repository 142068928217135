@import url('https://fonts.googleapis.com/css2?family=Azeret+Mono:ital,wght@0,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');

.App {
  width: auto!important; 
  overflow-x: hidden!important
}


footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem;
  text-align: center;
  flex-direction: column;
}


h3 {
  font-family: 'Azeret Mono';
  font-style: italic;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: start;
  color: #0b0b0b;
}

h2 {
  font-family: 'Azeret Mono';
  font-style: italic;
  font-weight: 900;
  font-size: 28px;
  line-height: 19px;
  text-align: start;
  color: #355E3B;
}

h4 {
  font-family: 'Azeret Mono';
  font-style: italic;
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  text-align: start;
  color: #355E3B;
}

.introduction-container{
  display: flex;
  width: 90%;
  flex-direction: row;
  justify-content: center ;
  margin: auto;
  padding-top: 2%;
}

.introduction-picture-container{
  width: 400px;
  display: inline-block;
}

.introduction {

  justify-content: center;
  padding: 1rem ;
  width: 50%;
  margin: auto;
  display: flex;



  flex-direction: column;
}

.side-by-side {
  display: flex;
  margin: auto;

  padding: 4rem 5rem;
  flex-direction: row;
  border-top: 1px solid #dedede;
}

.sarjapadding {
  padding: 1rem;
}

.aikataulu-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.full-container {
  width: 100%;
  margin: 0;
}

.side-by-side h2 {
  width: 600px;

}

.bold {
  font-weight: 800;
}

.italic {
  font-style: italic;
}

.text-container {
  display: inline-block;
  padding: 0;
  width: 50%;

  flex-direction: column;
}

.img-container {
  width: 400px;
  display: inline-block;
  align-items: flex-start;
  padding: 5rem;
  justify-content: space-between;
}

p {
  font-family: 'DM Sans';
  text-align: start;
  font-size: 15px;
  font-weight: 400;
}

p a {
  font-family: 'DM Sans';
  text-align: start;
  font-weight: 900;
  color:#D998D9;
 
}

.tekstikeskelle {
  text-align: center;
}

.a-link {
  font-family: 'DM Sans';
  text-align: start;
  font-weight: 600;
  text-decoration: underline;

}

.App-header {
  background-color: #eeece3;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  top: 0px;
  width: 100%;
}

.App-header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.headerpic {
  position: sticky;
  background-color: #eeece3;
}
.headerpic img {
  width: 100%;
  height: auto;
}


#saannot img {
  padding: 0rem;

}

nav {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
}

nav ul li {
  margin-right: 20px;
  width: 25%;
  text-align: center;
}

nav ul li a {
  font-family: 'Azeret Mono';
  color: #355E3B;
  text-decoration: none;
}

ol {
  padding: 0rem;
  font-family: "DM Sans";
  font-size: 15px;
  font-weight: 400;
}

li {
  padding: 0 20px;
  margin: 15px;
}

#saannot img {
  transition: transform .3s ease-in-out;
    -webkit-touch-callout: none; /* Disable callouts */
  -webkit-user-select: none; /* Disable selection */
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
 }
        



#saannot img:hover {

  transform: scale(1.07) rotate(15deg);
}

#saannot img:active {


  transform: rotate(360deg);
  animation: shake 0.7s ease-in-out;


}



#saannot {
  background-color: #eeece3;
  padding: 2rem;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  

}


#santtu-bubble {
  margin-top: 5%;
  margin-bottom: 5%;
  width: 80%;
}

#mobile-header-img {
  display: none ;
}

footer p {
  color: #adadad;
}

@keyframes shake {
  0% {
    transform: translateX(0) rotate(0deg); /* Alkuasento */
  }
  10% {
    transform: translateX(-5px) rotate(-5deg); /* Pieni liike vasemmalle */
  }
  20% {
    transform: translateX(5px) rotate(5deg); /* Pieni liike oikealle */
  }
  30% {
    transform: translateX(-5px) rotate(-5deg); /* Pieni liike vasemmalle */
  }
  40% {
    transform: translateX(5px) rotate(5deg); /* Pieni liike oikealle */
  }
  50% {
    transform: translateX(-5px) rotate(-5deg); /* Pieni liike vasemmalle */
  }
  60% {
    transform: translateX(5px) rotate(5deg); /* Pieni liike oikealle */
  }
  70% {
    transform: translateX(-5px) rotate(-5deg); /* Pieni liike vasemmalle */
  }
  80% {
    transform: translateX(5px) rotate(5deg); /* Pieni liike oikealle */
  }
  90% {
    transform: translateX(-3px) rotate(0deg); /* Pieni liike vasemmalle */
  }
  100% {
    transform: translateX(0) rotate(0deg); /* Palaa alkuasentoon */
  }



}



@media only screen and (max-width: 600px) {

  nav ul li {
    padding: 10px;
    margin-right: 10px; /* Reduce the margin between items */
  }

  nav ul li a {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  img:hover: {
    transform: scale(1.00) rotate(0deg);

  }
  .side-by-side {
    flex-direction: column;
    margin: 2rem auto; /* decrease the margin on the y-axis */
  }

  p {
    font-size: 14px;
  }

  li {
    font-size: 14px;
  }

 #img-header {
    display: none;
    visibility: hidden;
  }

  .imgheader {
    display: none;
  }

  .text-container {
    margin: 0;
    padding: 2rem;
    width: auto; /* make the text container full width */
  }

  h2 {
    font-size: 24px; /* decrease the font size */
    max-width: 100%;
    text-align: center; /* center the heading */
    margin: 1rem 0; /* add some space between the heading and the content */
    padding: 0;

  }

  .introduction {
    padding: 1rem;
    margin: auto;
    width: auto;
  }

  .side-by-side {
    margin: auto;
    width: auto;
    padding: 1rem;
  }

  .text-container {
    display: inline-block;
    margin: 0;
    padding: 0;
    width: 100%;

  }

  #mobile-header-img {
    display: block;
    width: 100%;
  }

  .img-container {
    display: flex;
    margin: 0 auto;
    object-fit: contain;
    margin-bottom: 5rem;
    min-height: 150px;
    align-items: center;
    width: 100vw;
    padding: 0;
    justify-content: center;
    padding-top: 10vh;
  }


  .App-header {
    background-color: #eeece3;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    top: 0px;
    width: 100%;
  }

  .kaljakroketti{
    display: none;
  }

  .introduction-container{
    display: flex;
    width: 100%;
    justify-content: center ;
    margin: auto;
    padding-top: 2%;
    flex-direction: column-reverse;
  }

  .introduction-picture-container{
    display: block;
    width: 60%;
    margin: 0 auto;
  }

  #santtu-bubble {
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
  }

  img {
    width: 70%;
  }

  .aikataulu-container {
    flex-direction: column;
    justify-content: center;
  }
  
  ol {
    padding: 0;
  }
  
  #krokesetti {
    display: none;
  }


  footer h3 {
    display: flex;
    justify-content: center;
    align-items: center;
  
  }

 
}

@media only screen and (max-width: 860px) {
  #saannot {
    padding: 2.5vw;
    width: 95vw;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .text-container {
    width: 90vw;
  }
}

@media only screen and (min-width: 600px) and (max-width: 860px) {
  h2 {
    font-size: 22px;
  }
}


